import dynamic from 'next/dynamic';
import Box from '@mui/material/Box';
import Typography  from '@mui/material/Typography'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { trimSp } from '@components/config/helpers';

const BoxContainer = dynamic(() => import("@components/Components/Reusables/BoxContainerV2"));
const StyledBox = dynamic(() => import("@components/Custom/Box/StyledBox"));

const styles = {
	cursor: 'pointer',
    marginLeft: '5px',
   	marginTop: '1px'
}

const HeaderBar1 = () => {

	const global_settings = useSelector(state => state.globalReducer.global_settings);

	return (
		<Box 
			className='pt-2 pb-2' 
			sx={{
				color:'primary',
				textAlign:'start', 
				backgroundColor: 'primary.main'/*alpha('#fff', 0.5)*/, 
			}}
		>
			<BoxContainer className={'d-flex justify-space-between'}>
				<Box>
					<Link href={`tel:${trimSp(global_settings.sales_telephone)}`}>
						<StyledBox className='text-vertical' sx={{color:'#fff',  display: 'flex', flexDirection:'row',}}>
							<LocalPhoneIcon size={10}/>
							<Typography sx={styles} className={`f-medium`} variant={'span'}>{global_settings.sales_telephone}</Typography>
						</StyledBox>
					</Link>
				</Box>
				<Box>
					<Link href={'/tracking'}>
						<StyledBox className='text-vertical' sx={{color:'#fff',  display: 'flex', flexDirection:'row', marginRight: 1.4}}>
							<LocationOnIcon size={10}/>
							<Typography sx={styles} className={`f-medium`} variant={'span'} >
							Track My Order
							</Typography>
						</StyledBox>
					</Link>
				</Box>
			</BoxContainer>
		</Box>
	)
}

export default HeaderBar1;